<template>
  <v-form
    :key="reference.id"
    ref="referenceForm"
    v-model="valid"
    @submit.prevent="createReference"
  >
    <v-row class="pa-3">
      <v-col
        cols="12"
        class="mt-2 pb-0"
      >
        <v-select
          v-model="newReference.type"
          :items="[
            'DOI',
            'PMID',
            'PDR',
            'URL'
          ]"
          label="Type"
          xreadonly="readonlyCode"
          dense
        />
        <v-text-field
          v-if="newReference.type !== 'URL'"
          v-model="newReference.code"
          label="Code"
          :loading="loadingCode"
          :readonly="readonly"
        />
        <template v-else>
          <v-text-field
            v-model="newReference.code"
            label="URL"
            :readonly="readonly"
          />
          <v-text-field
            v-model="newReference.title"
            label="Title"
            xreadonly="readonly"
          />
        </template>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
  export default {
    components: {
    },
    props: {
      open: Boolean,
      info: Boolean,
      readonly: {
        type: Boolean,
        default: false,
      },
      code: {
        type: String,
        default: null,
      },
      codeType: {
        type: String,
        default: 'PMID',
      },
      loadingCode: {
        type: Boolean,
        default: false,
      },
      reference: {
        type: Object,
        default: () => ({}),
      },

    },
    data () {
      return {
        newReference: {
          type: this.codeType,
          code: this.code,
          url: null,
          title: null,
        },
        readonlyCode: this.readonly,
        loading: false,
        valid: false,
      }
    },
    watch:{
      loading () {
        this.$emit('loading', this.loading)
      },
      loadingCode () {
        this.newReference.code = this.code;
      },
      codeType (type) {
        this.newReference.type = type.toUpperCase()
      },
      readonly () {
        this.readonlyCode = this.readonly;
      },
    },
    methods: {
      createReference () {
        this.loading = true;
        this.axios.post('admin/references',
          {
            code_type: this.newReference.type,
            code: this.newReference.code,
          },
        )
          .then((response) => {
            this.newReference = {
              type: null,
              code: null,
            };
            this.$emit('update-reference', response.data);
            this.$emit('reference-created');
            this.$toast.success('Reference created');
          })
          .catch(error => {
            this.$toast.error(error.response.data.code ? error.response.data.code[0] : error)
          })
          .finally(() => {
            this.loading = false
          });
      },
    },
  }
</script>
